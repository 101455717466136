import ForumIcon from '@material-ui/icons/Forum';
import IconButton from '@material-ui/core/IconButton';
export const IconMessages = (props) => {
    return(
        <div>
            <IconButton title={props.name}>
                <ForumIcon/>
            </IconButton>
        </div>
    )
}
