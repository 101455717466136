import { IconRepeat } from '../../UI/IconRepeat/IconRepeat';
import { IconLeft } from '../../UI/IconLeft/IconLeft';
import { IconRight } from '../../UI/IconRight/IconRight';
import { IconStar } from '../../UI/IconStar/IconStar';
import { IconLightning } from '../../UI/IconLightning/IconLightning';
import './Footer.css';
export const Footer = () => {
  return(
    <div className='swipeButtons'> 
      <IconRepeat/>
      <IconLeft/>
      <IconRight/>
      <IconStar/>
      <IconLightning/>
    </div>
  )
}
