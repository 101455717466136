import { IconMessages } from "../../UI/IconMessages/IconMessages";
import { IconProfile } from "../../UI/IconProfile/IconProfile";
import { Logo } from "../../UI/Logo/Logo";
import './Header.css';
export const Header = () => {
    return(
        <div className='header'>
            <IconProfile name='View Profile' />
            <Logo title='Tinder'/>
            <IconMessages name='View Messages'/>
        </div>
    )
}