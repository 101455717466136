import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import './IconRight.css'
export const IconRight = () => {
  return(
    <IconButton className='swipeButtons__right'>
      <FavoriteIcon/>
    </IconButton>
  )
}
