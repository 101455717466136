import IconButton from '@material-ui/core/IconButton';
import StarRateIcon from '@material-ui/icons/StarRate';
import './IconStar.css'
export const IconStar = () => {
  return(
    <IconButton className='swipeButtons__star'>
      <StarRateIcon/>
    </IconButton>
  )
}
