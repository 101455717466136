import { Header } from '../Layout/Header/Header';
import { Main } from '../Layout/Main/Main';
import { Footer } from '../Layout/Footer/Footer'
import './Home.css'
export const Home = () => {
    return(
        <div className='home'>
            <Header/>
            <Main/>
            <Footer/>
        </div>
    )
}
