import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './IconLeft.css'
export const IconLeft = (params) => {
  return(
      <IconButton className='swipeButtons__left'>
        <CloseIcon/>
      </IconButton>
  )
}
