import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
export const IconProfile=(props)=>{
    return(
        <div>
            <IconButton title={props.name}>
                <PersonIcon/>
            </IconButton>
        </div>
    )
}