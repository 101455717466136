import React,{ useState} from 'react';
import TinderCard from 'react-tinder-card';
import bryan from '../../../Images/bryan.jpeg'
import elian from '../../../Images/elian.jpeg'
import carro from '../../../Images/carro.jpeg'
import juan from '../../../Images/juan.jpeg'
import andrea from '../../../Images/andrea.jpeg'
import david from '../../../Images/david.jpeg'
import camilo from '../../../Images/camilo.jpeg'
import './Card.css'

export const Cards = () => {
    const [people,setPeople]=useState([
      {
        name:"Bryan Osorio",
        src:bryan
      },
      {
        name:'Elian Jaramillo',
        src:elian
      },
      {
        name:"Sebastian",
        src:carro
      },
      {
        name:"Juan Miguel",
        src:juan
      },
      {
        name:"Andrea Paola Mira",
        src:andrea
      },
      {
        name:"David Torres",
        src:david
      },
      {
        name:"El Mojo",
        src:camilo
      }
    ]);
    const swiped=(direction,nameToDelete)=>{
      console.log('removing' + nameToDelete);
    }
    const outOfFrame=(name)=>{
      console.log(name + 'left the screen');
    }
    return(
      <div className='cards'>
        <div className='cards__cardContainer'>
          {people.map((person)=>(
            <TinderCard
              className='swipe'
              key={person.name}
              preventSwipe={['up','down']}
              onSwipe={(dir)=>swiped(dir,person.name)}
              onCardLeftScreen={()=>outOfFrame(person.name)}
            >
              <div
                className='card'
                style={{backgroundImage:`url(${person.src})`}}
              >
                <h3>{person.name}</h3>
              </div>
            </TinderCard>
                ))}
        </div>
      </div>
    )
}
